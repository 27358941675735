import { useEffect, useState, useRef } from "react";
import "./style.scss"
import { Modal, Carousel, Button } from 'antd';
import { RightCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";
const HkClass2 = () => {
    let isTrue = false;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const carouselRef = useRef(null);

    useEffect(() => {
        var list = [{ title: "值得信赖的品牌", content: "Sinobus在全球有1家總公司和4家分公司，是海外華裔和港澳台家庭的共同選擇。" },
        { title: "教育理念", content: "Sinobus將國際最新語言習得理論——「5C」課程標準融入課程體系，註意培養孩子的綜合素質，並且通過有趣的情節、互動性遊戲創造沉浸式學習環境，讓孩子學習到具有豐富性、多樣性、實踐性的中文知識。" },
        { title: "價值觀", content: "為學生提供專業、有吸引力、優質的漢語服務與教育產品，成為線上教育行業裡的領跑者。" },
        { title: "良好的客戶口碑", content: "海外華裔和港澳台家庭都信賴的在線教育品牌，服務於全球80個國家和地區，學生續課率高達97%，業界口碑良好，用戶好評如潮。" },
        { title: "沉浸式教學場景，任務型教學模式", content: "在線直播互動課程，滿足孩子多種上課需求，電腦/平板/手機在線直連教師，足不出戶、隨時隨地體驗中文沉浸式學習。" },
        { title: "政府的大力支持", content: "進入21世紀以後，中國綜合國力與地位日益升高，國內外的漢語教學機構和資源儲備遠遠無法滿足日益增長的需求，這種背景下國家大力支持韓語教學機構推動漢語的傳播和交流，從根本上推動國家綜合實力的提升" },
        { title: "可視化的學習進度", content: "清晰完整的學習計劃讓家長放心，孩子開心。Sinobus提供完整的學習閉環，清晰透明的學習路徑，孩子的進步看得見。" },
        { title: "三位老師服務一個學生", content: "除了授課老師以外，Sinobus還配有專屬的班主任以及輔導老師，和家長溝通孩子的學習情況與計劃，提供督課等學習服務和技術支持。" }
        ];
        var firmInfo = document.querySelector(".firm-info");
        firmInfo.innerHTML = list.map((item, index) => {
            return (`<div class='firm-info-item' key=${index}><div class="firm-info-titlte">${item.title}</div><div class="firm-info-content">${item.content}</div></div>`)
        }).join("");

        if (isTrue === false) {
            isTrue = true;
            const scriptDiv1 = document.createElement('script');
            scriptDiv1.src = 'https://www.googletagmanager.com/gtag/js?id=AW-438384628';
            const scriptDiv2 = document.createElement('script');
            scriptDiv2.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MH29QND');`;
            const scriptDiv3 = document.createElement('script');
            scriptDiv3.innerHTML = ` window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
             gtag('js', new Date());
            gtag('config', 'AW-438384628');`;
            document.head.appendChild(scriptDiv3);
            document.head.appendChild(scriptDiv2);
            document.head.appendChild(scriptDiv1);
            const noscriptDiv = document.createElement('noscript');
            noscriptDiv.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MH29QND"
                height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
            document.body.appendChild(noscriptDiv);
            document.title = 'Sinobus 香港4-16歲学生補習｜專業數學、中文、英語課程專家';
        }
    }, [])
    const gtag_report_conversion = (url) => {
        console.log("==========");
        var callback = function () {
            if (typeof (url) != 'undefined') {
                //   window.location = url;
            }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-438384628/CqQfCL2x2aYYEPTvhNEB',
            'value': 1.0,
            'currency': 'USD',
            'event_callback': callback
        });
        return false;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'AW-438384628');

    const go = (data) => {
        let a = document.querySelector(data).getBoundingClientRect().top;
        console.log("a", a);
        window.scrollTo({
            top: a - 40,
            behavior: 'smooth' // 平滑滚动效果
        });
    }
    const goUrl = (url) => {
        // window.location.href = url;
        setIsModalOpen(true);
    }
    const handleOK = () => {
        window.location.href = "https://wa.me/8617788052878?text=%E4%B8%AD%E6%96%87%E8%A9%A6%E8%81%BD%E5%92%A8%E8%A9%A2";
    }
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return <div className="hk-box">
        <Modal title="" footer={null} open={isModalOpen} onCancel={handleCancel}>
            <div className="modal-box">
                <div className="icon"> <img src="../assets/logo.png" alt="" /></div>
                <div className="modal-title">你願意開始WhatsApp溝通嘛?</div>
                <div className="modal-btn">
                    <button className="chatBtn okBtn" onClick={() => { handleOK() }}>是</button>
                    <button className="no" onClick={() => { handleCancel() }}>否</button>
                </div>
            </div>
        </Modal >
        <a onClick={() => { goUrl() }}>
            <img className="chatUs" id="whatAppIcon" src="../assets/whatsapp.png" alt="" />
        </a>
        <div className="navigation">
            <button className="listening" onClick={() => { goUrl("https://wa.me/8617788052878?text=%E4%B8%AD%E6%96%87%E8%A9%A6%E8%81%BD%E5%92%A8%E8%A9%A2") }}>立即試堂</button>
            <button onClick={() => { go('.class-feature') }}>課程特色</button>
            <button onClick={() => { go('.course-info-box') }}>課程介紹</button>
            <button onClick={() => { go('.teacher-info') }}>教師资质</button>
            <button onClick={() => { go('.firm-box') }}>關於我們</button>
        </div>
        <div className="banner">
            <img className="banner-img" src="../assets/hkimg/bannerPuls1.png" alt="" />
            <img className="banner-img1" src="../assets/hkimg/bannerPuls.jpg" alt="" />
        </div>
        <img className="chatUs" onClick={() => { goUrl("https://wa.me/8617788052878?text=%E7%B7%9A%E4%B8%8A%E8%AB%AE%E8%A9%A2") }} src="../assets/Book.png" alt="" style={{ marginTop: '30px' }} />
        <h1 className="class-info-title">課程特色</h1>

        <div className="class-feature">
            <LeftCircleOutlined className="left-icon" onClick={() => { carouselRef.current.prev() }} />
            <Carousel className="carousel" dots={false} draggable={true} ref={carouselRef} >
                <div className="carousel-item" >
                    <img className="carousel-img" src="../assets/hkimg/lb1.jpg" alt="" />
                    <p>一對一私人課堂</p>
                </div>
                <div className="carousel-item">
                    <img className="carousel-img" src="../assets/hkimg/lb2.png" alt="" />
                    <p>互動式教學隨時回放</p>
                </div>
                <div className="carousel-item">
                    <img className="carousel-img" src="../assets/hkimg/lb5.jpg" alt="" />
                    <p>資深教研團隊</p>
                </div>
                <div className="carousel-item">
                    <img className="carousel-img" src="../assets/hkimg/lb4.png" alt="" />
                    <p>全程輔導 課後服務</p>
                </div>
                <div className="carousel-item" >
                    <img className="carousel-img" src="../assets/hkimg/lb3.png" alt="" />
                    <p>粵語/普通话/英文教學</p>
                </div>
            </Carousel>
            <RightCircleOutlined className="right-icon" onClick={() => { carouselRef.current.next() }} />
        </div>

        {/* <div className="class-info">
            <div className="class-info-item">
                <img src="../assets/hkimg/icon1.png" alt="" />
                <div className="line"></div>
                <h3 className="class-info-item-tilte">幼小銜接補習課程</h3>
                <p className="class-text">
                    課程設有看圖認字、筆順結構、聯想造詞、看圖等內容，安排闖關活動、識字遊戲，趣味性的練習，重視視覺感知、語音意識、語素意識、構字能力、書寫技巧的提升。真正將兒童的興趣、探索與創造融為一體，在帶動兒童興趣及思維的基礎上，體驗學習的快樂，並幫助孩子銜接小一課程。
                </p>
            </div>
            <div className="class-info-item">
                <img src="../assets/hkimg/02.png" alt="" />
                <div className="line"></div>
                <h3 className="class-info-item-tilte">全面提高班課程</h3>
                <p className="class-text">
                    小學全面提高班課程結合香港小學學習字詞表，整合《活學中國語文》《我愛學語文》《現代中國語文》等編撰而成，緊跟學校課程，拓展教材內容。中學同步提高班參考《啟思中國語文》等教材獨立研發提升學生能力課件，通過1對1的私人訂製課程，重視認字組句、閱讀理解、習作表達等方面，最終全面提升學生的語文能力。
                </p>
            </div>
            <div className="class-info-item">
                <img src="../assets/hkimg/03.png" alt="" />
                <div className="line"></div>
                <h3 className="class-info-item-tilte">呈分試補習課</h3>
                <p className="class-text">
                    課程包括聆聽、說話、閱讀習作三部分，注重中文綜合能力的提高。舉一反三，教授答題技巧，對錯題進行歸納分析，減少錯別字等失誤失分。熟悉不同文體的寫作技巧，掌握成語、修辭手法的運用，積累好句。對詞語、句子、語段進行分析，提升閱讀理解能力，學會審題，了解文章重要主旨。通過聆聽音頻，集中注意力，學會抓住重點。
                </p>
            </div>
            <div className="class-info-item">
                <img src="../assets/hkimg/04.png" alt="" />
                <div className="line"></div>
                <h3 className="class-info-item-tilte">閱讀理解與寫作專項提高班</h3>
                <p className="class-text">
                    小學閱讀課通過劃分句段、整合框架等方法了解文章內容主旨，提升閱讀敏感度，掌握答題技巧。小學寫作課主要是熟悉日記、記敍文、描寫文，說明文等文體，學習總分總框架組織等方法，整體提升習作能力。中學寫作專項課包括记敍、抒情和議論等更多文體的寫作技巧和知識。通過以讀帶寫，因材施教，課程內容多元化，激發學生寫作興趣，從審題、立意、構思、選材等方面入手，解決學生寫作難點，提升寫作能力。
                </p>
            </div>
            <div className="class-info-item">
                <img src="../assets/hkimg/04.png" alt="" />
                <div className="line"></div>
                <h3 className="class-info-item-tilte">文言文專項輔導課</h3>
                <p className="class-text">
                    根據中國語文課程指引為設計依據，以香港教育部發佈的中學建議篇章篇目及補充內容為教學重點，文言文專項課程包括文言實詞、虛詞，古今異義，特殊文言句式等多個考點，緊密貼合教材，多元拓展，積累文言文應試能力的同時提升中國文化知識，豐富文學素養，促進學生思辨能力發展。
                </p>
            </div>
            <div className="class-info-item">
                <img src="../assets/hkimg/icon1.png" alt="" />
                <div className="line"></div>
                <h3 className="class-info-item-tilte">白話文專項輔導課</h3>
                <p className="class-text">
                    白話文閱讀專項課程，品讀經典名家作品，學習多種閱讀策略，總結題型過程中幫助學生掌握白話文閱讀答題技巧，程難點螺旋上升，易於學生理解學習，滲入DSE題型，預早熟習公開試題。
                </p>
            </div>
            <div className="class-info-item">
                <img src="../assets/hkimg/icon.png" alt="" />
                <div className="line"></div>
                <h3 className="class-info-item-tilte">《現代小學數學》課程</h3>
                <p className="class-text">
                    課程設計基於香港小學《現代小學數學》內容、大綱文件《數學教育學習領域課程指導補充文件—小學數學科學學習內容》的指導方向，整合《數學課程進階訓練》《學生常犯錯500題》《應用題及解題思路》《26周學好數學》等相關配套練習編撰而成，全方位提升學生處理數、代數、度量、圖形與空間、數據處理問題的數學能力。
                </p>
            </div>
            <div className="class-info-item">
                <img src="../assets/hkimg/icon10.png" alt="" />
                <div className="line"></div>
                <h3 className="class-info-item-tilte">樂學英文</h3>
                <p className="class-text">
                    課程結合自然拼讀，能有效提升學員詞彙拼讀拼字能力，豐富多元的教學情景，讓學員沉浸式掌握文法知識點；課程緊貼考試大綱，設定形式多元的練習，提升學員的應試能力。本階段課程旨在幫助學生在聽、說、讀、寫各方面提升英語能力，並透過課程互動的方式，提升學生學習英語的興趣，提升學習效果。
                </p>
            </div>
        </div> */}
        <h1 className="class-info-title">課程介紹</h1>
        <div className="course-info-box">
            <div className="course-info-item">
                <div className="course-info-text">
                    一對一閱讀習作專項課
                    (P.3-S.3)
                </div>
                <div className="course-info-img">
                    <img src="../assets/hkimg/course1.png" alt="" />
                    <Button className="course-btn" type="primary" onClick={() => { goUrl() }}> 查看详情</Button>
                </div>
            </div>
            <div className="course-info-item">
                <div className="course-info-text">
                    一對一呈分試中文輔導課
                    (P.4-P.6)

                </div>
                <div className="course-info-img">
                    <img src="../assets/hkimg/course2.png" alt="" />
                    <Button className="course-btn" type="primary" onClick={() => { goUrl() }}> 查看详情</Button>
                </div>

            </div>
            <div className="course-info-item">
                <div className="course-info-text">
                    一對一小學數學
                    （P.1-P.6）
                </div>
                <div className="course-info-img">
                    <img src="../assets/hkimg/course3.png" alt="" style={{ margin: '20px 0 20px 0px' }} />
                    <Button className="course-btn" type="primary" onClick={() => { goUrl() }}> 查看详情</Button>
                </div>
            </div>
            <div className="course-info-item">
                <div className="course-info-text">
                    一對一樂學英文
                    （P.1-P.6）
                </div>
                <div className="course-info-img">
                    <div className="course-box">
                        <ul className="ul1">
                            <li className="name"></li>
                            <li className="name">推薦年齡</li>
                            <li className="name">對標香港課程</li>
                            <li className="name">Sino-bus等級</li>
                            <li className="name" id="tes">CEFR 歐洲共同語言參考標準</li>
                            <li className="name">劍橋少兒YLE</li>
                            {/* <li className="name" id="tes">TOEFL Primary TEST</li> */}
                            <li className="name">GESE</li>
                        </ul>
                        <ul className="ul2">
                            <li className="name">L0</li>
                            <li className="name">3-6</li>
                            <li className="name">K1-K3</li>
                            <li className="name">啟蒙</li>
                            <li className="name">BlowA1</li>
                            <li className="name">PreA1 starters </li>
                            {/* <li className="name"></li> */}
                            <li className="name">G1-G3</li>
                        </ul>
                        <ul className="ul3">
                            <li className="name">L1 L2 L3</li>
                            <li className="name">6-9</li>
                            <li className="name">P1-P3</li>
                            <li className="name">入門</li>
                            <li className="name">A1</li>
                            <li className="name">A1 Movers </li>
                            {/* <li className="name" id="tes">閱讀：107-113 聽力：105-112 口語：16-21</li> */}
                            <li className="name">G4-G6</li>
                        </ul>
                        <ul className="ul4">
                            <li className="name">L4 L5 L6</li>
                            <li className="name">9-12</li>
                            <li className="name">P4-P6</li>
                            <li className="name">中級</li>
                            <li className="name">A2</li>
                            <li className="name">A2 Flyers/KET</li>
                            {/* <li className="name" id="tes">閱讀：102-106 聽力：102-104 口語：10-15</li> */}
                            <li className="name">G7-G9</li>
                        </ul>
                    </div>
                    <Button className="course-btn" type="primary" onClick={() => { goUrl() }}> 查看详情</Button>
                </div>

            </div>
        </div>
        <h1 className="class-info-title">教師资质</h1>
        <div className="teacher-info">
            <div className="teacher-info-item teacher1">
                <div className="img-box">
                    <img className="teacher-img" src="../assets/hkimg/05.png" alt="" />
                </div>
                <h3 className="teacher-name">王君慧老師</h3>
                <p className="teacher-title">跨文化交流硕士</p>
                <div className="line-1 teacher-line"></div>
                <p className="teacher-text">悉尼大學畢業，持有普通話二甲證書 掌握普通話、粵語、日語、英語四種語言 漢語言文學學士學位 跨文化交流碩士學位 教學理念：致力讓學習中文成為一件快樂嘅事情。
                </p>
            </div>
            <div className="teacher-info-item teacher2">
                <div className="img-box">
                    <img className="teacher-img" src="../assets/hkimg/06.png" alt="" />
                </div>
                <h3 className="teacher-name">楊燕玲老師</h3>
                <p className="teacher-title">對外漢語資深教師</p>
                <div className="line-1 teacher-line"></div>
                <p className="teacher-text">擁有4年線上對外漢語教學經驗 普通話二甲證書 漢語言專業學士學位 學生累計1000多名，學生及家長好評率達99% 線上累記授課時長5000小時➕
                    教學理念：「一棵樹搖動另一棵樹，一朵雲推動另一朵雲，一個靈魂喚醒另一個靈魂。 」
                </p>
            </div>
            <div className="teacher-info-item teacher3">
                <div className="img-box">
                    <img className="teacher-img" src="../assets/hkimg/t7.png" alt="" />
                </div>
                <h3 className="teacher-name">王晶晶老師</h3>
                <p className="teacher-title">國際漢語注冊教師</p>
                <div className="line-1 teacher-line"></div>
                <p className="teacher-text">擁有10年教學工作經驗，擁有國內培訓老師、線上中文教師及線下英文老師等工作經驗。有心機，講課生動有趣，能夠根據學生嘅行為，語言方式，
                    心里特點嚟進行因材施教，擅於抓住學生注意力，所教授學生范圍3歲---成人，尤其對3--12歲學生嘅教學更為突出。學生及家長好評率達99%
                </p>
            </div>
        </div>

        <div className="teacher-resources">
            <p className="teacher-resources-title">師資力量雄厚，碩士畢業+資質齊全</p>
            <img className="teacher-img" src="../assets/hkimg/08.png" alt="" />
        </div>

        <div className="firm-box">
            <h1 className="class-info-title">公司簡介</h1>
            <p className=" firm-info-text">
                Sinobus由漢和文化科技有限公司創辦，總部位於中國·深圳，在中國·香港、中國·中山、中國·西安、美國·洛杉磯設有分公司。 公司專注於為4-16歲海外華裔和K1-S3的港澳台地區學童提供系統、正規、科學的中文數學英語培訓服務
            </p>
            <iframe className="myVideo" src="https://www.youtube.com/embed/LCXGjZW3T80?si=XphuzibP61_2GEvt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            {/* <video id="myVideo" controls>
                <source src="https://sino-java.oss-cn-shenzhen.aliyuncs.com/2025-01-02/%E5%90%B3%E6%80%9D%E7%91%A91735786638016.mp4?Expires=4889386638&OSSAccessKeyId=LTAI5t7JyJTMUGbf7rtfejnV&Signature=y46YEL4ZOl2JB%2FRhgaWjMzTO358%3D" type="video/mp4" />
            </video> */}
            <br />
            {/* <img src="../assets/hkimg/aboutUs.jpg" alt="" /> */}
            <div className="firm-info-box">
                <div className="firm-info"></div>
            </div>

        </div>
        <img className="chatUs" onClick={() => { goUrl('https://wa.me/8617788052878?text=%E7%B7%9A%E4%B8%8A%E8%AB%AE%E8%A9%A2') }} src="../assets/Book.png" alt="" style={{ marginTop: '30px' }} />

        <div className="map-box">
            <h1 className="class-info-title">業務分佈地圖</h1>
            <img className="map-img" src="../assets/hkimg/map1.png" alt="" />
        </div>
        <div className="footers">
            <div className="footer-item">
                <p className="footer-title">中國深圳辦公中心 | 全球總部</p>
                <p className="footer-p">漢和文化科技（深圳）有限公司</p>
                <p className="footer-p">地址： 中國深圳市南山區沙河街道文昌街社區
                    香山裏花園（二期）2棟1單元2B204</p>
                <p className="footer-p">電話：0755-86083397</p>
            </div>
            <div className="footer-item">
                <p className="footer-title">漢和文化科技（深圳）有限公司香港分公司
                    <br /> 中國香港辦公中心
                </p>
                <p className="footer-p">地址：RM023，9/F，BLK GKWAI SHING IND BLDG(STAGE2)24-46TAI LIN PAI RD，KWAICHUNG NT</p>
                <p className="footer-p">電話：852 56192953</p>
            </div>
            <div className="footer-item">
                <p className="footer-title">漢和文化科技（深圳）有限公司西安分公司<br /> 中國西安辦公中心 | 教研基地</p>
                <p className="footer-p">地址： 中國西安市雁塔區曲江池南路金地湖城大境2棟1單元101室</p>
                <p className="footer-p">電話：029-89123322</p>
            </div>
            <div className="footer-item">
                <p className="footer-title">漢和文化科技（深圳）有限公司中山分公司</p>
                <p className="footer-p">地址： 中國中山市港口鎮興港中路136號四樓之四</p>
                <p className="footer-p">電話：076088512186</p>
            </div>
            <div className="footer-item">
                <p className="footer-title">美國洛杉矶辦公中心 | 運營基地</p>
                <p className="footer-p">Los Angeles, USA Limited</p>
                <p className="footer-p">地址：75 egret, Irvine,California, 92618</p>
                <p className="footer-p">電話：+1 2403026131</p>
            </div>
        </div>
    </div>
}
export default HkClass2;